header {
    background-repeat: no-repeat;
    background-size: cover;
    // background-image: url(../img/b-d.jpg);
    background-image: url(../img/foto-4.jpg);
    height: 65vw;
    background-position: center center;
}

header nav a.nav-link {
    color: var(--bs-dark-rgb);
    font-weight: 600;
}

header nav a.btn {
    font-weight: 600;
}

header nav a.btn > span {
    font-weight: 900;
}

header #C {
    margin-top: 20vw;
    color: #fff;
}

header .h1 {
    color: #fff;
    font-weight: 700;
}

header .btn-light {
    background-color: #ffffff;
    color: $primaryDark;
    // min-width: 200px;
}

header .btn-light:hover,
header .btn-light:active {
    background-color: #fff;;
}

header #L {
    display: block;
    width: 270px;
    height: auto;
    // height: 171px;
    background-image: url(../img/logo-2.png), radial-gradient(circle at center, white 0, transparent 60%);
    background-size: 65%, 80%;
    background-repeat: no-repeat;
    background-position: center center;
    aspect-ratio: 1.58;
}

@media screen and (max-width: 40em) and (orientation: portrait) {
    header {
        background-repeat: no-repeat;
        background-size: cover;
        // background-image: url(../img/bg-1-m.jpg);
        background-image: url(../img/movel-4.jpg);
        height: 120vh;
        background-position: top center;
    }
    header #L {
        width: 180px;
        background-size: 85%, 80%;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
