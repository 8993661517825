@import "root.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "./header.scss";

// @import url('https://fonts.cdnfonts.com/css/futura-md-bt');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;500;600&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    // font-family: 'Futura', sans-serif;
    // font-family: 'Futura Hv BT', sans-serif;
    font-family: 'Jost', sans-serif;
    
}

img.f1 {
    width: 100%;
    max-width: 100%;
    // height: 640px;
    max-width: 640px;
}

.st {
    letter-spacing: 5px;
    text-transform: uppercase;
}

#Parceiros {
    background-color: #FCF6F0;
}

#Parceiros .h3 {
    font-size: 1.5rem;
}

#Parceiros img {
    width: auto;
    max-height: 70px;
    margin-top: 1em;
    margin-bottom: 1em;
}

.bg-gradient2 {
    background: rgb(179,89,17);
    background: linear-gradient(90deg, rgba(179,89,17,1) 0%, rgba(212,140,4,1) 100%);
}

#bg-t {
    background-repeat: no-repeat;
    background-image: url(../img/bg-t.png);
    background-position: left center;
    background-size: contain;
    width: 100%;
    height: 50px;
    display: block;
}

a[name] {
    text-decoration: none;
}

#Programas img {
    width: 100%;
    max-width: 100%;
    height: 180px;
    object-fit: cover;
}

#Programas a {
    text-decoration: none;
    color: #000;
}

#Programas .h3 {
    font-size: 1.4em;
    display: inline-block;
    padding-right: 27px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 0.624967C16 0.459216 15.9298 0.300253 15.8047 0.183049C15.6797 0.0658447 15.5102 0 15.3334 0H7.33379C7.15699 0 6.98743 0.0658447 6.86241 0.183049C6.73739 0.300253 6.66716 0.459216 6.66716 0.624967C6.66716 0.790719 6.73739 0.949681 6.86241 1.06689C6.98743 1.18409 7.15699 1.24993 7.33379 1.24993H13.7241L0.195498 13.9318C0.133518 13.9899 0.0843521 14.0589 0.0508085 14.1348C0.0172648 14.2107 0 14.2921 0 14.3742C0 14.4564 0.0172648 14.5378 0.0508085 14.6137C0.0843521 14.6896 0.133518 14.7586 0.195498 14.8167C0.257479 14.8748 0.331061 14.9209 0.412043 14.9524C0.493024 14.9838 0.57982 15 0.667474 15C0.755128 15 0.841923 14.9838 0.922905 14.9524C1.00389 14.9209 1.07747 14.8748 1.13945 14.8167L14.6667 2.13364V8.12457C14.6667 8.29032 14.737 8.44929 14.862 8.56649C14.987 8.6837 15.1566 8.74954 15.3334 8.74954C15.5102 8.74954 15.6797 8.6837 15.8047 8.56649C15.9298 8.44929 16 8.29032 16 8.12457V0.624967Z' fill='black'/%3E%3C/svg%3E");
    background-position: center right;
    background-repeat: no-repeat;
}


.bg-2 {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../img/bg2-d.jpg);
    height: 60vw;
    background-position: center bottom;
}

.bg-2 .content {
    margin-top: 7%;
}

.h-100 {
    height: 100%;
}

#AppContainer img {
    width: auto;
    height: 70px;
}

#Contato {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../img/bg-3.jpg);
    background-position: center center;
}

footer img {
    max-height: 80px;
    width: auto;
}

footer {
    background-color: #393939;
}

footer a {
    text-decoration: none;
}

.t {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 1em;
}

.parceiros {
    margin-bottom: 0;
}

.floating {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 25px;
    right: 25px;
    background-color: #25d366;
    color: #fff;
    border-radius: 25px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.floating:hover {
    color: #fff;
}
   
.fab-icon {
    margin-top: 10px;
}

#C .btn.btn-light {
    // width: 100%;
    width: 220px;
}

#btn-aovivo {
    margin-top: 15vh;
}

.ad {
    background-image: url("data:image/webp;base64,UklGRmwAAABXRUJQVlA4WAoAAAAQAAAABAAABAAAQUxQSBYAAAABDzAhERFCadtIbAwea/+oRfQ/GvwBVlA4IDAAAACQAQCdASoFAAUAAgA0JaQAApplHeAA/tPpPS2F/PjfDX7Mr9vZ2Xy98v/pAAAAAAA=");
}

@media screen and (max-width: 1023px) and (orientation: portrait) {
    img#L {
        max-height: 150px;
        max-width: unset;
        height: unset;
        width: auto;
    }
    #C {
        margin-top: 10vh !important;
    }
    ul.about.d-flex {
        flex-direction: column;
    }
    ul.about.d-flex li {
        width: 100% !important;
    }
    .parceiros.d-flex {
        flex-wrap: wrap;
    }
    .parceiros.d-flex li {
        width: 33%;
    }
    #bg-t {
        height: 30px;
        background-size: 180%;
    }
    .bg-2 {
        height: 110vh;
        background-image: url(../img/bg2-m.jpg);
    }
    .bg-2 .content {
        margin-top: 30%;
    }
    #AppContainer .img-fluid {
        max-width: 130px;
        height: auto;
    }

    #offcanvasResponsive {
        background-color: $primary;
        color: #fff;
        max-width: 320px;
    }

    #offcanvasResponsive .offcanvas-body {
        padding: 0;
    }

    #offcanvasResponsive #Menu {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    #offcanvasResponsive #Menu .nav-item {
        border-top: 1px dotted rgba(255, 255, 255, 0.4);
        width: 100%;
        padding: 0.5em 0;
        font-size: 1em;
    }

    #offcanvasResponsive #Menu .nav-link {
        font-weight: 400;
    }

    #offcanvasResponsive #Menu .nav-item:first-child {
        border-top: unset;
    }

    #btn-aovivo > span {
        display: none;
    }

    #btn-aovivo {
        margin-top: 25vh;
    }

    // #btn-aovivo {
    //     margin-top: 10em;
    //     margin-left: 5%;
    //     width: 90%;
    //     padding-top: 1em;
    //     padding-bottom: 1em;
    //     text-align: center;
    //     font-weight: 700 !important;
    //     border: 1px solid #fff;
    // }
    .floating {
        bottom: 15px;
        right: 15px;
    }
    footer {
        padding-bottom: 2em;
    }
}


